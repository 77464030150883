<template>
    <div class="row d-flex nav-bar">
        <a
            role="button"
            @click="goBack"
        ><i class="bi bi-chevron-left" /></a>
        <h5 class="col nav-bar-text">
            {{ title }}
        </h5>
        <a
            v-if="rightNav === 'home'"
            role="button"
            @click="goHome"
        ><i class="bi bi-house-door" /></a>
        <a
            v-else-if="rightNav === 'trash'"
            role="button"
            @click="doReset()"
        ><i class="bi bi-trash3-fill" /></a>
        <a
            v-else
            role="button"
            @click="doReset()"
        ><i class="bi bi-arrow-counterclockwise" /></a>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'TopNavigationBar',
    props: {
        title: {
            type: String,
            default: '',
        },
        rightNav: {
            type: String,
            default: '',
        },
        isCalculator: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        backRoutePath () {
            var current = this.$router.currentRoute.path
            var path = _
                .chain(current)
                .split('/')
                .dropRight(1)
                .join('/')
                .value()
            return path || '/'
        },
    },
    methods: {
        goBack () {
            if (this.isCalculator) {
                this.$emit('click')
            } else {
                this.$emit('leave')
                this.$router.replace({ path: this.backRoutePath })
            }
        },
        goHome () {
            if (this.isCalculator) {
                this.$emit('click')
            } else {
                this.$emit('leave')
                this.$router.push({ name: 'home' })
            }
        },
        doReset (event) {
            console.log(this.prevRoute)
            this.$emit('click')
        },
    }
}
</script>
